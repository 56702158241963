@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

 .txt-card-title{
    text-align: center;
 }
.txt-card {
  width: 405px;
  
  /* position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box; */
  /* border: 1pxsolidrgba(0,0,0,.125); */
  /* border-radius: .25rem; */
  background-color: #eaebed !important;
  box-shadow: 10px 15px 16px #eaebed;
  border: 2px solid #1b57a7 !important;
  border-radius: 15px !important;
  /* box-shadow: 0px 0px 10px 5px #d1d1d3,inset 0px 0px 15px #d1d1d3; */
}
.txt-body {
  /* background-color: #fafafa; */
  justify-content: center;
  align-content: center;
  width: 63%;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding-top: 118px;
  padding-left: 533px;
}
.txt-module{
  
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 25px;
  justify-content: center;
  padding: 30px 0px 30px 0px;
    /* justify-content: space-between; */
    
}

.txt-box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1{
    font-family: Microsoft Sans Serif;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .active{
    color:#1a58a8 !important;
  }
  a{
    text-decoration: none !important;
  }
  a:hover{
    color:#e2b538 !important;
  }
  /* Navbar Css */
  .navbar {
    background-color: #EAEBED;
    height: 149px;
    z-index: 1;
  }
  .logoimages{
    position: relative;
    width: 364px;
    height: 220px;
    left: -26px;
    top: 10px;
    overflow: visible;
  }
  .nav-link{
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: rgba(112,112,112,1);
    display: block;
    padding: 10px 50px;
  }
  .navbar-nav{
    margin-left: 60px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 35%;
  }
  /* .nav-item{
    width: 100px;
  } */
  .input-search{
    font-size: 16px;
    line-height: 1.5;
    background: #ffffff;
    border: 1px solid #D5D9DB;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%238C92A0' d='M11.44 10.73l4.41 4.42a.5.5 0 1 1-.7.7l-4.42-4.41a6.5 6.5 0 1 1 .7-.7v-.01zM6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'></path></svg>");
    background-repeat: no-repeat;
    /* background-color: #3a0aeb; */
    background-position: 15px 10px;
    background-size: 20px 20px;
    border-radius: 100px;
    width: 300px;
    padding: .5em 1em .5em 2.5em;
  }
  .input-search::placeholder{
    color: #838D99;
  }
  
  .input-search:focus {
    outline: none;
    background-color: #FFF;
    border: 1px solid #84A2FA;
  }

  .signinheader li{
    list-style-type: none;
  }

  .signinheader{
    position: absolute;
    right: 225px;
  }

  .signinheader ul li{
    display: inline-block;
  }

  .signinheader ul li a{
    color:#1a58a8 !important;
  }
  .btn-primary{
    color:#ffffff !important;
    background-color:#1a58a8 !important;
    border-color: #1a58a8 !important;
  }
  .mb5{
    margin-bottom: 10px !important;
  }
  
  .mb-33 {
      margin-bottom: 0rem!important;
  }
  
  .user-signinbadge{
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: box-shadow .15s ease-in-out;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    height: 33px;
    vertical-align: middle;
    margin-bottom: 3px;
    color:#1a58a8;
    margin-left: 235px;
  }
  .nav-icon{
    position: absolute;
    right: 80px;
    top: 28px;
  }
  .rkp-nav-link{
    color: rgba(112,112,112,1) !important;
  }
  @media only screen and (max-width: 680px) {
    .navbar {height: 76px ;}
    .logoimages{
      position: absolute;
      width: 169px;
      height: 102px;
      left: 0px;
      top: -10px;
      overflow: visible;
    }
    .navbar-toggler{
      border-color: rgb(0 0 0 / 0%) !important;
    }
    .navbar-toggler-icon{
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzFhNThhOCI+PHBhdGggZD0iTTE0LjMzMzMzLDM1LjgzMzMzdjE0LjMzMzMzaDE0My4zMzMzM3YtMTQuMzMzMzN6TTE0LjMzMzMzLDc4LjgzMzMzdjE0LjMzMzMzaDE0My4zMzMzM3YtMTQuMzMzMzN6TTE0LjMzMzMzLDEyMS44MzMzM3YxNC4zMzMzM2gxNDMuMzMzMzN2LTE0LjMzMzMzeiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+) 50% 50% no-repeat !important;
    }
    .navbar-collapse{
      margin-top: 28px;
      background-color: #c1cce2;
      border-radius: 12px;
    }
    .signinheader{
      display: none;
    }
  }
  @media only screen and (max-width: 768px){
    .navbar {height: 76px ;}
    .logoimages{
      position: absolute;
      width: 169px;
      height: 102px;
      left: 0px;
      top: -10px;
      overflow: visible;
    }
    .navbar-toggler{
      border-color: rgb(0 0 0 / 0%) !important;
    }
    .navbar-toggler-icon{
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzFhNThhOCI+PHBhdGggZD0iTTE0LjMzMzMzLDM1LjgzMzMzdjE0LjMzMzMzaDE0My4zMzMzM3YtMTQuMzMzMzN6TTE0LjMzMzMzLDc4LjgzMzMzdjE0LjMzMzMzaDE0My4zMzMzM3YtMTQuMzMzMzN6TTE0LjMzMzMzLDEyMS44MzMzM3YxNC4zMzMzM2gxNDMuMzMzMzN2LTE0LjMzMzMzeiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+) 50% 50% no-repeat !important;
    }
    .navbar-collapse{
      margin-top: 28px;
      background-color: #c1cce2;
      border-radius: 12px;
      z-index: 5;
    }
    .signinheader{
      display: none;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px){
    .navbar {height: 76px ;}
    .logoimages{
      position: absolute;
      width: 169px;
      height: 102px;
      left: 0px;
      top: -10px;
      overflow: visible;
    }
    .navbar-toggler{
      border-color: rgb(0 0 0 / 0%) !important;
    }
    .navbar-toggler-icon{
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzFhNThhOCI+PHBhdGggZD0iTTE0LjMzMzMzLDM1LjgzMzMzdjE0LjMzMzMzaDE0My4zMzMzM3YtMTQuMzMzMzN6TTE0LjMzMzMzLDc4LjgzMzMzdjE0LjMzMzMzaDE0My4zMzMzM3YtMTQuMzMzMzN6TTE0LjMzMzMzLDEyMS44MzMzM3YxNC4zMzMzM2gxNDMuMzMzMzN2LTE0LjMzMzMzeiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+) 50% 50% no-repeat !important;
    }
    .navbar-collapse{
      margin-top: 28px;
      background-color: #c1cce2;
      border-radius: 12px;
      z-index: 5;
    }
    .signinheader{
      display: none;
    }
  }
  @media (min-width: 992px){
    .user-signinbadge{
      display: none;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px){
    .navbar {height: 149px ;}
    .logoimages{
      position: absolute;
      width: 364px;
      height: 220px;
      left: 0px;
      top: -28px;
      overflow: visible;
    }
    .nav-link{
      overflow: visible;
      width: 93px;
      white-space: nowrap;
      text-align: center;
      font-family: Neue Haas Grotesk Display Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      color: rgba(112,112,112,1);
      display: block;
      padding: 10px 50px;
    }
    .navbar-nav{
      margin-left: 350px;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 40%;
    }
    .nav-item{
      width: 100px;
    }
    .signinheader li{
      list-style-type: none;
    }
  
    .signinheader{
      position: absolute;
      right: 50px;
    }
  
    .signinheader ul li{
      display: inline-block;
    }
  
    .signinheader ul li a{
      color:#1a58a8 !important;
    }
    .mb5{
      margin-bottom: 10px !important;
    }
  }

  /* Footer */
  .footer-section {
    background: #0d3974;
    position: relative;
  }
  .footer-content {
    position: relative;
    z-index: 2;
  }
  .footer-pattern a {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
  }
  .footer-header {
    margin-bottom: 30px;
  }
  .footer-header a h1{
    color: #ffffff;
  }
  .footer-header a{
    text-decoration: none;
  }
  .footer-header hr{
    color: #D5D9DB;
  }
  .footer-text p {
    margin-bottom: 14px;
    font-size: 24px;
    color: #fff;
    line-height: 28px;
  }
  .footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  .footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
  }
  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 25%;
    transition: transform 0.4s linear 0s;
  }
  .footer-social-icon i:hover{
    transform: rotate(360deg);
    border-radius: 50%;
  }
  .facebook-bg{
    background: #3B5998;
  }
  .instagram-bg {
    background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  }
  .twitter-bg{
    background: #55ACEE;
  }
  .google-bg{
    background: #DD4B39;
  }
  .footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }
  .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #ff5e14;
  }
  .footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
  }
  .footer-widget ul li a:hover{
    color: #ff5e14;
  }
  .footer-widget ul li a {
    color: #fff;
    text-decoration: none;
    text-transform: capitalize;
  }
  .copyright-area{
    background: #0d3974;
    padding: 25px 0;
  }
  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
  }
  .copyright-text p a{
    color: #ff5e14;
  }
  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }
  .footer-menu li:hover a{
    color: #ff5e14;
  }
  .footer-menu li a {
    font-size: 14px;
    color: #878787;
  }
  .footer-bg{
    background-color: #EAEBED;
    height: 149px;
  }
  .footer-img{
    position: relative;
    width: 364px;
    height: 220px;
    left: -26px;
    top: -23px;
    overflow: visible;
  }

  .footer-pos{
    position: relative;
  }
  .signin-footer li{
    list-style-type: none;
  }

  .signin-footer{
    position: absolute;
    right: 225px;
    top: 51px;
  }

  .signin-footer ul li{
    display: inline-block;
  }

  .signin-footer ul li a{
    color:#0d3974 !important;
  }
  @media only screen and (max-width: 680px) {
    .footer-bg {height: 76px ;}
    .footer-img{
      position: relative;
      width: 169px;
      height: 102px;
      left: -13px;
      top: -8px;
      overflow: visible;
    }
    .signin-footer{
      position: absolute;
      top: 12px;
      right: 20px;
    }
    .footer-text p {
      font-size: 14px;
    }
    .footer-social-icon{
      margin-bottom: 25px;
    }
  }
  @media only screen and (max-width: 768px){
    .footer-bg {height: 76px ;}
    .footer-img{
      position: relative;
      width: 169px;
      height: 102px;
      left: -13px;
      top: -8px;
      overflow: visible;
    }
    .signin-footer{
      position: absolute;
      top: 12px;
      right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px){
    .footer-bg {height: 76px ;}
    .footer-img{
      position: relative;
      width: 169px;
      height: 102px;
      left: -13px;
      top: -8px;
      overflow: visible;
    }
    .signin-footer{
      position: absolute;
      top: 12px;
      right: 20px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px){
    .signin-footer{
      position: absolute;
      right: 50px;
    }
  }

  /* Section First Home */
  .img-video{
    width: 99%;
    padding-top: 73px;
  }
  .pb-50{
    padding-bottom: 100px;
  }
  .first-section{
    position: relative;
    overflow: hidden;
  }
  .ellipse-circle{
    overflow: visible;
    position: absolute;
    top: -260px;
    right: -40px;
  }
  .one-img{
    height: 1200px;
    width: 1200px;
    position: absolute;
    top: -280px;
    right: -35px;
  }
  #circle-ellipse{
    opacity: 0.25;
    fill: rgba(213,221,236,1);
  }
  .circle-img{
    position: absolute;
    right: 240px;
    top: 150px;
  }
  .circle-image{
    height: 600px;
    width: 750px;
  }
  .section-one-home{
    padding: 305px 0 0 70px;
    max-width: 655px;
  }
  .title-one{
    color: #1a58a8;
    font-style: normal;
    font-weight: bold;
    padding-bottom: 25px;
  }
  .title-one h1{
    font-size: 74px;
  }
  .section-one-home p{
    text-align: left;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
  }
  .home-section{
    background-image: linear-gradient(90deg, #1a58a8 0%, #59c0e9 100%);
    width: 300px;
    height: 100px;
    
    transition: transform 0.4s linear 0s;
    text-align: left;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    overflow: visible;
    color: #fff;
  }
  .first-section-button button{
    border: aliceblue;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: rgba(234,235,237,1);
  }
  .home-section:hover{
    background-image: linear-gradient(90deg, #59c0e9 0%, #1a58a8 100%);
    transform: rotate(360deg);
  }
  /* Popup style */
  .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .box {
    position: fixed;
    width: auto;
    margin: 0 auto;
    height: auto;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 4px;
    padding: 20px;
    overflow: auto;
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 0;
    opacity: 2;
    z-index: 2;
    right: calc(35% - 30px);
    top: calc(120vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
  .play-button{
    width: auto;
    font-size: 110px;
    position: absolute;
    display: flex;
    top: 38%;
    left: 35%;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  @media only screen and (max-width: 680px){
    .section-2 p {
      font-size: 20px;
    }
    .one-img{
      height: 285px;
      width: 285px;
      top: -40px;
      right: -14px;
    }
    
    .circle-image {
      width: 244.984px;
      height: 195.987px;
    }
    .circle-img {
      position: absolute;
      right: 75px;
      top: 20px;
    }
    .section-one-home {
      padding: 27px 20px 0 20px;
      text-align: center;
    }
    .title-one h1 {
      font-size: 42px;
    }
    .section-one-home p {
      font-size: 18px;
      text-align: center;
    }
    .home-section {
      width: 150px;
      height: 50px;
    }
    .first-section-button button {
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 768px){
    .play-button{
      top: 35%;
      left: 35%;
      font-size: 55px;
    }
    .one-img{
      height: 285px;
      width: 285px;
      top: -15px;
      right: -14px;
    } 
    .circle-image {
      width: 244.984px;
      height: 195.987px;
    }
    .circle-img {
      position: absolute;
      right: 65px;
      top: 25px;
    }
    .home-section {
      width: 150px;
      height: 50px;
    }
    .first-section-button button {
      font-size: 10px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px){
    .play-button{
      top: 34%;
      left: 35%;
      font-size: 70px;
    }
    .one-img{
      height: 485px;
      width: 485px;
      position: absolute;
      top: -55px;
      right: -35px;
    }
    .circle-image {
      height: 250px;
      width: 364px;
    }
    .circle-img {
      position: absolute;
      right: 70px;
      top: 80px;
    }
    .section-one-home {
      padding: 110px 0 0 15px;
      max-width: 310px;
    }
    .title-one h1 {
      font-size: 35px;
    }
    .section-one-home p {
      font-size: 16px;
    }
    .home-section {
      width: 150px;
      height: 50px;
    }
    .first-section-button button {
      font-size: 18px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px){
    .play-button{
      top: 38%;
      left: 37%;
      font-size: 85px;
    }
    .one-img {
      height: 680px;
      width: 680px;
      right: -45px;
      top: -115px;
    }
    .circle-image {
      height: 345px;
      width: auto;
    }
    .circle-img {
      position: absolute;
      right: 120px;
      top: 70px;
    }
    .section-one-home {
      padding: 135px 0 0 25px;
      max-width: 335px;
    }
    .title-one h1 {
      font-size: 35px;
    }
    .section-one-home p {
      font-size: 16px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1600px){
    .one-img{
      height: 800px;
      width: 800px;
      top: -160px;
      right: -40px;
    }
    .circle-img {
      position: absolute;
      right: 150px;
      top: 125px;
    }
    .circle-image {
      height: 400px;
      width: auto;
    }
    .section-one-home {
      padding: 175px 0 0 30px;
      max-width: 460px;
    }
    .title-one h1 {
      font-size: 50px;
    }
    .section-one-home p {
      font-size: 18px;
    }
  }
  @media only screen and (min-width: 1600px) and (max-width: 1800px){
    .one-img{
      height: 1100px;
      width: 1100px;
      position: absolute;
      top: -215px;
      right: -20px;
    }
    .circle-img {
      position: absolute;
      right: 210px;
      top: 140px;
    }
    .circle-image {
      height: 540px;
      width: auto;
    }
  }

  /* Second Section */
  .second-section{
    padding-bottom: 200px;
  }
  .bg-img-second{
    background-image: url(../public/images/ellipse.png);
    background-repeat: no-repeat, repeat;
    position: relative;
    background-position: -50px 0px;
    margin-top: -50px;
  }
  .section-title{
    max-width: 700px;
    margin: 50px auto 75px;
    text-align: center;
    position: relative;
  }
  .section-title h1{
    font-size: 56px;
    color: #707070;
  }
  .disp-whyrevoo{
    
  }
  .content-two{
    padding-top: 15px;
    padding-left: 25px;
  }
  .content-two h1{
    text-align: left;
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    color: rgba(53,53,53,1);
  }
  .content-two p{
    text-align: left;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
  }
  .why-content{
    display: inline-flex;
    padding: 35px 0;
  }
  .content-second{
    padding-top: 75px;
  }
  @media only screen and (max-width: 680px){
    .bg-img-second{
      background-position: -190px 0px;
      background-size: 383px 383px;
      background-repeat: no-repeat, repeat;
    }
    .disc-image{
      display: none;
    }
    .content-two{
      padding: 0;
    }
    .content-two h1{
      text-align: center;
      font-size: 25px;
    }
    .content-two p{
      text-align: center;
      font-size: 18px;
    }
    .section-title{
      margin: 50px auto 50px;
    }
    .second-section {
      padding-bottom: 50px;
    }
  }

  /* Third Section */
.third-section{
  padding: 50px;
  margin-top: -200px;
}
.slick-dots {
  display: none !important;
}
.app-slider{
  padding-bottom: 50px;
}
.bg-img-third{
  background-image: url(../public/images/ellipseyellow1.png),url(../public/images/ellipseyellow1.png),url(../public/images/ellipseyellow2.png),url(../public/images/ellipseyellow2.png),url(../public/images/ellipseyellow2.png);
  background-repeat: no-repeat, no-repeat;
  background-size: 174px 174px,auto,auto,200px 200px,auto;
  background-position: 1560px 200px,1200px 500px,80px 600px,450px 200px,1100px 300px;
}
.card{
  height: 430px;
  background-color: #eaebed !important;
  box-shadow: 10px 15px 16px #eaebed;
  border: 2px solid #1b57a7 !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 10px 5px #d1d1d3,inset 0px 0px 15px #d1d1d3;
}
.app-card-pad{
  padding: 16px 90px !important;
}
.card .inner {
  /* padding: 2em; */
  padding: 0 80px;
  border-radius: 4px;
  background-color: #f2f2f2;
  word-wrap: break-word;
  position: relative;
  z-index: 3;
  /* box-shadow: 0px 1px 1px 0px rgba(128, 122, 122, 0.75); */
}
.card-title{
  text-align: center;
  padding-top: 53px;
}
.card-title h1{
  font-size: 34px;
}
.card-p{
  text-align: center;
}
.card-p p{
  font-size: 24px;
}
.app-functions{
  display: inline-flex  ;
}
.app-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.app-button{
    border: aliceblue;
    font-size: 42px;
    color: rgba(234,235,237,1);
    text-align: center;
    padding-bottom: 50px;
}
.button-app{
  background-image: linear-gradient(90deg, #1a58a8 0%, #59c0e9 100%);
  overflow: visible;
  width: 115px;
  height: 45px;
  font-size: 20px;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  font-weight: normal;
  border-radius: 20px;
}
.app-button button{
  color: aliceblue;
  border: #0593ff;
}
.app-button button:hover{
  background-image: linear-gradient(90deg, #59c0e9 0%, #1a58a8 100%);
  border-radius: 10px;
  border: #55ACEE;
}
@media only screen and (max-width: 680px){
  .third-section{
    margin-top: -75px;
    padding: 0 25px;
  } 
  .section-title h1{
    font-size: 42px;
    color: #1a58a8;
  }
  .app-card-pad{
    padding: 20px 15px !important;
  }
  .card{
    height: auto !important;
  }
  .bg-img-third{
    background-image: url(../public/images/ellipseyellow2.png);
    background-repeat: no-repeat, no-repeat;
    background-size: auto;
    background-position: 10px 550px;
  }
}
@media only screen and (max-width: 768px){
  .card{
    height: auto !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px){
  .app-functions{
    flex-wrap: wrap;
    padding: 0;
  }
  .card{
    height: auto !important;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1790px){
  .app-functions{
    padding: 0;
  }
}
@media only screen and (min-width: 1790px){
  .app-functions{
    padding: 10px 35px;
  }
}


/* Fourth Section */
.bg-img-fourth{
  background-image: linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)),url(../public/images/design1.png);
  background-repeat: no-repeat;
  /* background-position: 0px; */
  /* opacity: 0.15; */
  z-index: 1;
  margin-left: -85px;
  /* display: flex; */
  overflow: hidden;
  background-size: 104%;
}
.fourth-section{
  width: auto;
}
.roadmap {
  display: flex;
  padding: 250px 0px;
}
.roadmap-title h1{
  padding-bottom: 50px;
  text-align: center;
}
.get-started{
  display: flex;
  justify-content: center;
}
.get-started button{
  width: 145px;
  height: 40px;
  background-image: linear-gradient(90deg, #1a58a8 0%, #59c0e9 100%);
  overflow: visible;
  font-size: 15px;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  font-weight: normal;
  border-radius: 20px;
  color: aliceblue;
  border: #0593ff;
}
.get-started button:hover{
  background-image: linear-gradient(90deg, #59c0e9 0%, #1a58a8 100%);
  border-radius: 5px;
  border: #55ACEE;
  color: #fff;
}
.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem
}
@media (max-width:668px) {
  .roadmap{
    padding: 0;
  }
}
@media (min-width:2075px) {
  .bg-img-fourth{
    padding-bottom: 80px;
  }
}
@media (min-width:768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
      content: "";
      display: block;
      border-top: .25rem dotted #3b82f6;
      width: 3.46rem;
      position: absolute;
      left: 7.5rem;
      top: .3125rem
  }
  .timeline-steps .timeline-step:not(:first-child):before {
      content: "";
      display: block;
      border-top: .25rem dotted #3b82f6;
      width: 3.8125rem;
      position: absolute;
      right: 7.5rem;
      top: .3125rem
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6
}

.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #3b82f6;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: .5
}
.subtitle-1{
  padding: 50px;
}
.subtitle-1 h1{
  font-family: Microsoft Sans Serif;
  font-size: 56px;
}
.subtitle-1 p{
  font-family: Neue Haas Grotesk Display Pro;
  font-size:24px;
}
@media (max-width:668px) {
  .subtitle-1 p{
    font-size:18px;
  }
  .subtitle-1 h1 {
    font-size: 32px;
}
}
.correct-fourth{
  margin-left: 85px;
}
.image-flex{
  max-width: 100%;
}
.mid-line{
  overflow: hidden;
}
.two-img-midline{
  margin-top: -150px;
}
.section-2{
  text-align: right;
}
.section-2 h1{
  padding-top: 150px;
  font-size: 32px;
}
.section-2 p{
  padding-top: 10px;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 24px;
}
.section-2-img{
  position: relative;
  display: flex;
}



/* Testonomials */
.testinomial{
  background-color: #1b57a7;
}
.testinomial-section{
  max-width: 100%;
  padding: 60px 51px;
}
.testinomial-review{
  color: #fff;
  padding: 0;
}
.testinomial-review h1{
  font-size: 56px;
  font-family: Microsoft Sans Serif;
}
.testinomial-review p{
  font-size: 24px;
  font-family: Neue Haas Grotesk Display Pro;
}

.label-txt{
  width: 100%;
    height: 80px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 25px;
    display: inline-block;
    
}
.purchase-txt{
  display: flex;
}


/* Mission Vision */
  .mission-vision{
    padding: 100px 0;
  }
  .mission{
    padding: 30px 0;
  }
  .mission h1{
    font-size: 69px;
    font-family: Neue Haas Grotesk Display Pro;
    color: #0d3974;
  }
  .mission p{
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 24px;
    color: #0d3974;
  }
  .vision{
    padding: 30px 0;
  }
  .vision h1{
    font-size: 69px;
    font-family: Neue Haas Grotesk Display Pro;
    color: #0d3974;
  }
  .vision p{
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 24px;
    color: #0d3974;
  }


  /* Trail */
  .trail{
    padding: 80px;
    background-image: url('../public/images/about-circle.png'),url('../public/images/trail-back.png');
    background-repeat: no-repeat,no-repeat;
    background-position: -250px 100px,250px 300px;
    background-size: 45%,auto;
    overflow: hidden;
  }
  @media (max-width:768px) {
    .trail{
      padding: 60px 5px;
    }
    .form-head h1{
      font-size: calc(1.375rem + 1.5vw);
    }
    .form-head p{
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 680px){
    .section-2 p {
      font-size: 18px;
    }
    .testinomial-review p {
      font-size: 18px;
    }
    .mission p {
      font-size: 18px;
    }
    .vision p {
      font-size: 18px;
    }
  }
  .form-head{
    text-align: center;
  }
  .form-head h1{
    font-family: Microsoft JhengHei UI;
    font-size: 72px;
    color: #1a58a8;
    font-weight: bold;
  }
  .form-head p{
    font-family: Microsoft Sans Serif;
    font-size: 38px;
    color: #707070;
  }
  hr.trail-hr{
    border: 4px solid #1a58a8;
    opacity: 1 !important;
  }
  .trail-form{
    padding: 25px;
  }
  .form-control{
    padding: 15px 0;
  }
  
  .rkp-control {
    width: 100%;
    height: 80px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 25px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #eaebed;
    box-sizing: border-box;
  }
  .rkp-form{
    justify-content: space-between;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  label[for=component] {
    padding: 12px 20px;
  }
  input[type=number], select[type=number] {
    width: 100%;
    height: 80px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 25px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #eaebed;
    box-sizing: border-box;
  }
  
  /* input[type=email], select[type=email] {
    width: 100%;
    height: 80px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 25px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #eaebed;
    box-sizing: border-box;
  } */
  textarea.form-control{
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 25px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #eaebed;
    box-sizing: border-box;
  }
  .trail-form .container .row .col-lg-6,.col-lg-12{
    padding: 15px;
  }
  span.optional{
    color:#838D99;
    float: right;
  }
  .accept-tm{
    font-size: 29px;
    color:#707070;
  }
  .accept-tm span{
    color:#deb539;
  }
  .start-button button{
    color: aliceblue;
    border: #0593ff;
  }
  .start-button{
    padding: 40px 0;
  }
  .start-button .btn{
    background-image: linear-gradient(90deg, #1a58a8 0%, #59c0e9 100%);
    overflow: visible;
    width: 155px;
    height: 56px;
    font-size: 20px;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    border-radius: 10px;
  }
  .start-button .btn:hover{
    background-image: linear-gradient(90deg, #59c0e9 0%, #1a58a8 100%);
    color: #fff;
  }


  /* About */
  .about-head{
    background-image: url(../public/images/about-cir.png),url(../public/images/about-circle.png);
    background-position: 85px 350px, 1314px 135px;
    background-repeat: no-repeat,no-repeat;
  }
  .about-title{
    position: relative;
    padding: 50px;
    max-width: 100% !important;
    text-align: center;
    max-width: 100%;
  }
  .about-title h1{
    font-family: Microsoft JhengHei UI;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    color: #0d3974;
  }
  .about-head-img{
    padding: 50px;
    max-width: 100%;
  }
  .about-title img{
    max-width: 100%;
  }
  img.about-triangle{
    width: 100%;
  }
  .triangle-about{
    max-width: 100%;
    position: relative;
  }
  .triangle-section{
    overflow: hidden;
  }
  .triangle-one{
    position: relative;
    /* width: 40%; */
    text-align: center;
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    color: rgba(13,57,116,1);
    padding: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  /* .triangle-one a{
    position: absolute;
  } */
  .triangle-two{
    position: relative;
    /* width: 40%; */
    text-align: center;
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    color: rgba(13,57,116,1);
    display: flex;
    justify-content: center;
  }
  .triangle-div{
    position: relative;
    /* display: flex; */
  }
  .triangle-inside{
    position: absolute;
    top: 10%;
    padding: 100px;
    width: 80%;
  }
  .employees{
    display: grid;
    justify-content: left;
  }
  .users{
    display: grid;
    justify-content: right;
  }
  .partners{
    display: grid;
    justify-content: left;
  }
  .amount{
    font-family: Microsoft JhengHei UI;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    color: rgba(222,181,57,1);
  }
  .name{
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    color: rgba(112,112,112,1);
  }
  .meet-our-team{
    padding: 25px;
    /* margin: 50px; */
  }
  .team-title h1{
    text-align: center;
    margin: 75px 0px 0px 0;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: rgba(13,57,116,1);
    font-size: 72px;
  }
  .team-image{
    display: flex;
    justify-content: center;
  }
  .team-role{
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    color: rgba(222,181,57,1);
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .about-team{
    text-align: center;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: rgba(13,57,116,1);
  }
  .offer-header{
    padding: 30px;
  }
  .offer-header h1{
    font-family: Microsoft JhengHei UI;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    color: rgba(13,57,116,1);
    text-align: center;
  }
  img.about-offer-img{
    width: 100%;
  }
  /* .about-offer-header{
    padding-top: 50px;
  } */
  .about-offer-header h1{
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 46px;
    color: rgba(222,181,57,1);
  }
  .about-offer-header p{
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    color: rgba(13,57,116,1);
  }
  .about-offer{
    padding: 30px;
  }
  @media (max-width:988px) {
    .triangle-one{
      font-size: 20px;
    }
    .triangle-two{
      font-size: 20px;
    }
  }
  @media (max-width:1200px) {
    .about-head-img{
      /* max-width: 100%; */
    }
    .about-head{
      background-image: none;
    }
  }
  @media (max-width:798px) {
    .about-title h1{
      font-size: 54px;
    }
  }
  @media (max-width:668px) {
    .about-title h1{
      font-size: 34px;
    }
  }
  @media (max-width:488px) {
    .team-title h1{
      font-size: 46px;
    }
  }
  @media (max-width:488px) {
    .offer-header h1 {
      font-size: 46px;
    }
  }




  /* Product page */
  .product-head{
    position: relative;
    background-image: url(../public/images/circleproduct.png);
    background-repeat: no-repeat;
    background-position: -100px 300px;
  }
  .product-head-img{
    display: block;
  }
  .product-head-img img{
    width: 60%;
    float: right;
  }
  .product-title-slider{
    padding: 150px;
  }
  .product-title-slider h1{
    padding-top: 100px;
    font-family: Microsoft JhengHei UI;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    color: rgba(27,87,167,1);
    margin-bottom: 10px;
  }
  .product-title-slider p{
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    color: rgba(112,112,112,1);
    margin-bottom: 50px;
  }
  .our-app hr{
    opacity: 1 !important;
    color: #1a58a8;
    height: 3px !important;
    width: 90%;
  }
  .our-app-title{
    margin-bottom: 50px;
  }
  .our-app-title h1{
    text-align: center;
    font-family: Microsoft JhengHei UI;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    color: rgba(222,181,57,1)
  }
  .app-name{
    font-family: Microsoft Sans Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 56px;
    color: rgba(13,57,116,1);
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .product-card{
    width: 30%;
    background-color: #eaebed !important;
    border-radius: 15px !important;
    height: 196px;
  }
  .product-card-title{
    text-align: left;
  }
  .product-card-title h1{
    font-size: 25px;
    padding: 10px 10px 10px 25px;
    height: 47px;
    color: #ffffff;
    background-color: #0d3974;
    border-radius: 11px 11px 0px 0px;
  }
  .product-card-p{
    text-align: center;
  }
  .product-card-p p{
    font-size: 24px;
  }
  .product-app-functions{
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
  }
  .product-img img{
    width: 122px;
    height: 101px;
    padding-left: 30px;
    padding-top: 15px;
  }
  .product-star-img{
    width: 20px;
  }
  .product-app-button-one .button-one{
    font-family: Neue Haas Grotesk Display Pro;
    background-color: white;
    border: 2px solid #1a58a8;
    border-radius: 7px;
    width: 108px;
    color: #1a58a8;
    font-weight: 500;
  }
  .product-app-button-two{
    padding-top: 14px;
    width: 160px;
  }
  .product-app-button-two .button-app{
    width: 108px;
    border-radius: 9px;
    height: 30px;
    font-size: 12px;
    border: aqua;
    color: #ffffff;
    font-weight: 500;
  }
  .product-app-button-one{
    font-size: 14px;
    padding-top: 14px;
  }
  .product-static{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 60px;
    column-gap: 60px;
    row-gap: 60px;
  }
  .our-app{
    padding-bottom: 30px;
  }
  .map-canvas{
    width: 100%;
    height: 450;
    border: 0;
  }
  iframe{
    width: 100%;
    height: 350px;
  }

  /* .product-star-img{
    color: #ffffff;
  } */
  @media (max-width:1200px) {
    .product-img img {
      width: 108px;
      height: 100px;
      padding-left: 18px;
      padding-top: 15px;
    }
    .product-static {
      column-gap: 35px;
    }
    .product-card-title h1 {
      font-size: 20px;
    }
    .product-button{
      padding-left: 37px;
    }
  }
  @media (max-width:991px) {
    .product-card {
      width: 45%;
    }
  }
  @media (max-width:768px) {
    .product-card {
      width: 100%;
    }
  }
  @media only screen and (max-width: 768px) and (min-width: 734px) {
    .our-app-title h1 {
      font-size: 59px;
    }
  }
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .product-card {
      width: 29%;
    }
  }
  @media only screen and (max-width: 1024px) and (min-width: 1500px) {

  }

  @media (max-width:1500px) {
    .product-title-slider{
      padding: 120px;
    }
    .product-title-slider h1{
      padding-top: 80px;
      font-size: 54px;
    }
    .product-title-slider p{
      font-size: 24px;
      margin-bottom: 50px;
    }
  }
  @media (max-width:1200px) {
    .product-head{
      background-image: none;
    }
    .product-title-slider{
      padding: 90px;
    }
    .product-title-slider h1{
      padding-top: 60px;
      font-size: 36px;
    }
    .product-title-slider p{
      font-size: 18px;
      margin-bottom: 50px;
    }
  }
  @media (max-width:768px) {
    .product-title-slider{
      padding: 15px;
    }
    .product-title-slider h1{
      padding-top: 60px;
      font-size: 28px;
    }
    .product-title-slider p{
      font-size: 18px;
      margin-bottom: 50px;
    }
  }
/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}
